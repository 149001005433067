<template>
  <!-- social links corner section -->
  <div class="socialLinks-wrapper">
    <div class="socialLinks-content">
      <a
        class="social-link"
        href="https://github.com/davyjonesdesign"
        alt="Davy's Github Profile"
        title="Davy's Github Profile"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            d="M14.204.111a15.97622,15.97622,0,0,0-4.00812,30.78236.73678.73678,0,0,0,.96195-.80162v-2.5652a6.61563,6.61563,0,0,1-1.44293.16032A3.45889,3.45889,0,0,1,6.348,24.64066a3.32649,3.32649,0,0,0-.96195-1.60324c-.481-.16033-.6413-.16033-.6413-.32065,0-.32065.481-.32065.6413-.32065.96195,0,1.76358,1.12227,2.08423,1.60324a2.92191,2.92191,0,0,0,2.24454,1.60325,3.20131,3.20131,0,0,0,1.44292-.32065A4.06321,4.06321,0,0,1,12.761,22.39612c-3.68747-.80163-6.413-2.88585-6.413-6.413a7.55687,7.55687,0,0,1,1.9239-4.80974,5.94654,5.94654,0,0,1-.32065-2.24455,5.49483,5.49483,0,0,1,.481-2.56519,7.21525,7.21525,0,0,1,4.4891,2.08422,8.67724,8.67724,0,0,1,3.04617-.481,10.33733,10.33733,0,0,1,3.2065.481,6.91238,6.91238,0,0,1,4.48909-2.08422,7.96868,7.96868,0,0,1,.32065,2.56519,5.33073,5.33073,0,0,1-.32065,2.24455,7.223,7.223,0,0,1,1.9239,4.80974c0,3.52715-2.72552,5.61137-6.413,6.413a4.94328,4.94328,0,0,1,1.60324,3.68747V30.252a.87362.87362,0,0,0,1.12228.80163A16.09545,16.09545,0,0,0,14.204.111Z"
          />
        </svg>
        <div class="social-link_label">GitHub</div>
      </a>
      <a
        class="social-link"
        href="https://instagram.com/davyjonesdesign"
        alt="Davy's Instagram Profile"
        title="Davy's Instagram Profile"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            d="M9.68294,0A9.68294,9.68294,0,0,0,0,9.68294V22.31706A9.68294,9.68294,0,0,0,9.68294,32H22.31186A9.68814,9.68814,0,0,0,32,22.31186V9.68294A9.68294,9.68294,0,0,0,22.31706,0ZM25.58333,4.41667a2,2,0,1,1-2,2A2.00073,2.00073,0,0,1,25.58333,4.41667ZM16,7a9,9,0,1,1-9,9A9.00906,9.00906,0,0,1,16,7Zm0,3a6,6,0,1,0,6,6A6,6,0,0,0,16,10Z"
          />
        </svg>
        <div class="social-link_label">Instagram</div>
      </a>
      <a
        class="social-link"
        href="https://davyjonesdesign.threadless.com"
        alt="Davy's Shirt Shop"
        title="Davy's Shirt Shop"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            d="M30.61063,12.93748c-0.04539-0.38629-0.08683-0.77357-0.12827-1.16035c-0.06512-0.61323-0.1332-1.24718-0.21806-1.86732
                  c-0.20523-1.66801-0.54268-3.32911-1.00347-4.9394c-0.08979-0.29502-0.2003-0.593-0.30785-0.88161
                  c-0.0444-0.11791-0.0888-0.23631-0.13123-0.35472c-0.27134-0.71289-0.7933-1.30293-1.46919-1.66011
                  c-1.24027-0.67934-2.55455-1.23288-3.90534-1.64531c-0.00481-0.0016-0.00962-0.00321-0.01443-0.00475
                  c-0.35546-0.11748-0.71881-0.20277-1.08641-0.26456c-0.05038-0.00857-0.102-0.00913-0.15257-0.01677
                  c-0.31044-0.04656-0.9368-0.10249-0.93687-0.10237c-0.00389-0.00025-0.00777-0.00105-0.01165-0.0013
                  c-1.06879,1.81697-2.62888,3.18482-4.47326,3.70932c-0.50875,0.14468-1.04453,0.14468-1.55328,0
                  c-1.84439-0.5245-3.40453-1.89235-4.47332-3.70932c-0.00389,0.00025-0.63808,0.0571-0.94852,0.10366
                  c-0.05057,0.00765-0.10218,0.0082-0.15257,0.01677C9.27674,0.22114,8.91339,0.30643,8.55793,0.42391
                  C8.55312,0.42545,8.54831,0.42705,8.5435,0.42865C7.19272,0.84109,5.87844,1.39463,4.63816,2.07397
                  C3.96228,2.43115,3.44031,3.0212,3.16897,3.73408C3.12655,3.85249,3.08214,3.97089,3.03774,4.0888
                  C2.93019,4.37741,2.81968,4.67539,2.72989,4.97041C2.26911,6.5807,1.93166,8.2418,1.72643,9.90981
                  c-0.08486,0.62014-0.15294,1.25409-0.21806,1.86732c-0.04144,0.38678-0.08288,0.77406-0.12827,1.16035
                  c0.0037,0.00222,0.00678,0.00345,0.01048,0.00561c-0.03188,0.12568,0.02744,0.23662,0.13327,0.2677l0.04045,0.00987
                  c0.34349,0.10231,0.68557,0.20955,1.02715,0.31624c0.00598,0.00241,0.01412,0.00654,0.02004,0.00888
                  c0.73564,0.29638,1.35313,0.4138,1.66159,0.49927c0.30094,0.08338,0.60879,0.12876,0.93539,0.17662
                  c0.28121,0.04119,0.3816,0.06099,0.44204,0.06808c0.00456,0.00056,0.00783,0.00037,0.01233,0.00086
                  c0.00025,0.00006,0.00043,0.00006,0.00068,0.00012c0.33622,0.04718,0.61909,0.07542,0.82623,0.09293
                  c0.00222-0.0198,0.00333-0.04218,0.00549-0.06204c0.00456-0.00019,0.01215,0,0.01659-0.00019
                  c0.04298-0.48589,0.07524-0.92645,0.1007-1.32365c0.00395-0.05772,0.00691-0.11649,0.01061-0.17477
                  c0.01591-0.26258,0.02972-0.50987,0.03984-0.72411c0.04792-1.01445,0.07184-1.52167,0.03552-2.01804
                  C6.67766,9.79591,6.66946,9.50946,6.65121,9.2243C6.6459,9.11083,6.64418,8.99027,6.63788,8.87902
                  c-0.02572-0.36748-0.03608-0.6815-0.04052-0.94599C6.5991,7.90614,6.59854,7.8864,6.6007,7.85834
                  C6.61605,7.65946,6.63869,7.52046,6.74297,7.4583c0.12352-0.07357,0.30791-0.00752,0.4178,0.08005
                  c0.16681,0.13283,0.20048,0.34571,0.21337,0.43556c0.2328,1.61732,0.2505,2.31281,0.2505,2.31294
                  c0.0045,0.17742,0.0095,0.2854,0.01474,0.37106c0.03077,3.38868,0.07499,4.63013,0.09429,6.01958
                  c-0.00222,1.59475-0.01283,2.86234-0.01283,2.8624c0,0.00012-0.20196,9.81163-0.43852,10.82213
                  c-0.04588,0.19598-0.06235,0.26394-0.02251,0.38339c0,0.00006,0.01492,0.17051,0.15836,0.21504
                  c1.83032,0.56815,3.72946,0.90393,5.64439,0.99557c0.21313,0.01381,0.42625,0.01973,0.64036,0.02565
                  c0.07672,0.00173,0.15343,0.00456,0.23015,0.00703l0.1144,0.01961c0.04792,0.00148,0.09546,0.00117,0.14332,0.00191l0.0124,0.00203
                  c0.18155,0.00198,0.36508,0.00099,0.54959,0.00592c0.2381,0.00678,0.47651,0.00814,0.7148,0.01184
                  c0.17273,0.00265,0.34793,0.00888,0.51727,0.00888c0.00352,0,0.00703-0.00018,0.01054-0.00018s0.00697,0.00018,0.01048,0.00018
                  c0.16934,0,0.34454-0.00623,0.51727-0.00888c0.23829-0.0037,0.4767-0.00506,0.7148-0.01184
                  c0.18451-0.00493,0.36804-0.00394,0.54959-0.00592l0.0124-0.00203c0.04785-0.00074,0.0954-0.00043,0.14332-0.00191l0.1144-0.01961
                  c0.07672-0.00247,0.15343-0.0053,0.23015-0.00703c0.21411-0.00592,0.42724-0.01184,0.64037-0.02565
                  c1.91493-0.09164,3.81407-0.42742,5.64439-0.99557c0.14344-0.04453,0.15836-0.21498,0.15836-0.21504
                  c0.03984-0.11945,0.02337-0.18741-0.02251-0.38339c-0.23656-1.0105-0.43852-10.82201-0.43852-10.82213
                  c0-0.00006-0.01061-1.26766-0.01283-2.8624c0.0193-1.38945,0.06352-2.6309,0.09429-6.01958
                  c0.00524-0.08566,0.01024-0.19364,0.01474-0.37106c0-0.00012,0.0177-0.69562,0.2505-2.31294
                  c0.01289-0.08985,0.04656-0.30273,0.21337-0.43556c0.10989-0.08757,0.29428-0.15362,0.4178-0.08005
                  c0.10428,0.06216,0.12691,0.20116,0.14227,0.40004c0.00216,0.02775,0.0016,0.0473,0.00333,0.07394
                  c-0.00432,0.26456-0.0148,0.57894-0.04052,0.94673c-0.00629,0.11125-0.00802,0.23181-0.01332,0.34528
                  c-0.01825,0.28516-0.02646,0.5716-0.0473,0.85658c-0.03632,0.49637-0.01239,1.00359,0.03552,2.01804
                  c0.01011,0.21337,0.0238,0.45968,0.03965,0.72103c0.00376,0.06,0.00691,0.12062,0.01098,0.18007
                  c0.02541,0.39659,0.0576,0.83647,0.10052,1.32143c0.00444,0.00019,0.01196,0,0.01653,0.00019
                  c0.00216,0.01986,0.00327,0.04224,0.00549,0.06204c0.20714-0.01751,0.49008-0.04576,0.8263-0.09293
                  c0.00025-0.00006,0.00043-0.00006,0.00068-0.00012c0.0045-0.00049,0.00777-0.00031,0.01233-0.00086
                  c0.06043-0.00709,0.16083-0.02689,0.44204-0.06808c0.3266-0.04785,0.63444-0.09324,0.93539-0.17662
                  c0.30847-0.08547,0.92595-0.20289,1.66159-0.49927c0.00592-0.00234,0.01406-0.00648,0.02004-0.00888
                  c0.34158-0.10669,0.68365-0.21393,1.02715-0.31624l0.04045-0.00987c0.10582-0.03108,0.16515-0.14202,0.13326-0.2677
                  C30.60384,12.94093,30.60693,12.9397,30.61063,12.93748z"
          />
        </svg>
        <div class="social-link_label">Shirt Shop</div>
      </a>
    </div>
  </div>
</template>

<script></script>

<style scoped>
/* Social links */
.social-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 5px;
  fill: var(--heavy);
  color: var(--heavy);
  height: 100%;
  min-width: 100px;
}
.social-link_label {
  /* height: 0; */
  opacity: 0;
  transition: var(--transition-faster);
  color: var(--text);
}
.social-link svg {
  transform: rotate(-5deg);
  width: 40px;
  transition: var(--transition-faster);
  padding-bottom: 10px;
}
.social-link:hover svg {
  fill: var(--text);
  transform: scale(1.25) rotate(10deg);
}
.social-link:hover .social-link_label {
  opacity: 1;
}
.socialLinks-content {
  /* transform: rotate(30deg) ; */
  /* background: var(--blue-new); */
  /* position: absolute; */
  /* right: -175px;
  top: -40px; */
  /* width: 500px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0x 10px;
  margin: 0 20px;
  z-index: 100;
  border-bottom: 2px solid var(--light);
}
@media (max-height: 450px) {
  .social-link svg {
    max-width: 35px;
  }
  .social-link_label {
    display: none;
  }
}
</style>
