<template>
  <div class="logo-wrap">
    <div class="logo-wrapper">
      <router-link to="/" class="logo">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 418.8 659.4"
          style="enable-background: new 0 0 418.8 659.4"
          xml:space="preserve"
        >
          <g>
            <path
              d="M145.3,353.9c54.7-13.2,108.4-29.3,158.8-55c25-12.7,48.4-27.8,67.7-48.4c33.7-36,34.8-81.3,2.9-118.6
              c-18.4-21.5-41.8-36.7-66.6-49.7c-44.7-23.5-92.5-38.9-141.2-51.7C114.7,16.8,61.7,8.2,8.4,0.8C0.7-0.3,0.2-1.9,0.2,8.1
              c0,60.5,0.1,120.9,0,181.4H0.1c0,61.2,0,122.5-0.1,183.7c0,5.2,1.6,6.1,6.4,5.4C52.9,371.8,99.5,364.9,145.3,353.9z M85,93
              c56.9,11.3,112.9,25.6,166.1,49.3c18.7,8.3,36.8,17.8,52.6,30.8c2.9,2.6,5.6,5.4,8.2,8.3c4.1,4.5,4.1,11.4-0.1,15.8
              c-4.3,4.5-9.4,9.6-12.6,12c-23.4,17.6-50,29.1-77.3,39.4c-44,16.6-89.5,27.9-135.5,36.9c-6.2,1.2-6.3,1.1-6.3-5.4
              c0-30.5,0-60.9,0-91.4h0c0-30.5,0-60.9-0.1-91.4C80.1,93.7,81,92.2,85,93z"
            />
            <path
              d="M418.8,478.2c0-58.7,0-117.4,0-176.1c-0.1-1.3-0.4-2.7-0.7-3.9c-0.9,0.6-1.8,1.2-2.7,1.9c-20.8,19.4-44.3,34.8-69.5,47.7
              c-4.8,2.5-6.4,5.3-6.4,10.6c0.2,67.6,0.2,135.2,0.1,202.8c0,6.5-0.1,6.6-6.4,5.3c-39.2-7.6-77.9-17.1-115.7-29.9
              c-33.8-11.5-66.9-24.6-96-45.6c-6.8-4.9-12.5-11.2-18.3-17.3c-1.2-1.2-1.1-5.1,0-6.6c2.9-4,6.2-7.6,9.9-10.9
              c15.2-13.1,32.8-22.4,50.8-31.1c1.2-0.7,2.3-1.4,3.4-2.2c-1.2-0.4-2.4-0.7-3.7-1c-21.8-2.4-43.6-2.5-65.3,1.7
              c-27.7,5.5-51.8,17.4-71,38.5c-5.6,6.1-6.6,12.4-5.3,20c4.3,25.5,18.9,44.7,37.7,61.3c24,21,51.8,35.8,80.9,48.5
              c49.9,21.7,102,36.9,155.4,47.4c38.3,7.6,77,13.1,115.5,19.6c7.3,1.2,7.3,1.2,7.3-5.9C418.9,594.7,418.8,536.4,418.8,478.2z"
            />
          </g>
        </svg>
        <!-- <div class="nav-logo_text">
          <h1>Davy Jones</h1>
          <h5>Graphic + Web Designer</h5>
        </div> -->
      </router-link>
    </div>
  </div>
</template>
<style scoped>
.logo-wrapper {
  display: inherit;
  border-radius: var(--border-radius);
  padding: 0;
}
.logo-wrapper a,
.router-link-exact-active {
  border-radius: var(--border-radius);
  padding: 0;
  background: none;
  box-shadow: none;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10000;
  border-radius: var(--border-radius);
  /* padding: 4px 15px; */
  transition: var(--transition);
}
.nav-logo_text {
  padding-left: 20px;
  transition: var(--transition);
  /* min-width: 270px; */
}
.nav-logo_text h1 {
  /* font-size: 36px; */
  letter-spacing: 1px;
  font-weight: 700;
  transition: var(--transition);
  color: var(--logo);
}
.nav-logo_text h5 {
  transition: var(--transition);
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 0px;
  opacity: 0;
  /* height: 0; */
  padding-top: 3px;
  margin: 0;
  overflow: hidden;
}
.logo svg {
  fill: var(--logo);
  width: 40px;
  transition: var(--transition);
  padding: 20px 0px 10px;
}

.logo:hover svg {
  fill: var(--heavy-op);
  transform: rotate(360deg);
  transition: all 0.5s ease-in-out;
}
</style>
